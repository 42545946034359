import React from 'react'
import Styled from '@emotion/styled';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import GridSlideShow from './gridSlideShow';

const GridItem = ({ p, i, isOffice = false }) => (

    <StyledGridItem className="column is-one-third property">
        {
            p.status && p.status != "available" ?
                <Available>
                    <p className="is-size-7 is-white is-uppercase">{p.status}</p>
                </Available>
                : <></>
        }

        {p.gallery && p.gallery.length > 1 && p.featured_image ? (
            <GridSlideShow
                type="grid"
                link={isOffice ? '#' : '/' + p.slug.toLowerCase()}
                images={
                    isOffice ? p.gallery.map(e => e.childImageSharp.fluid) : [
                        p.featured_image.childImageSharp.fluid,
                        ...p.gallery.
                            filter(({ localFile }) => localFile).
                            map(({ localFile }) => localFile.childImageSharp.fluid,
                            ),
                    ]}
            />
        ) : (
            <Img fluid={p.featured_image.childImageSharp.fluid} />
        )}



        <div className="columns is-mobile">
            <div className="column">
                {
                    isOffice ?
                        <>
                            <p className="is-uppercase">
                                {p.title}

                            </p>
                            <p
                                className="meta is-black is-size-7 is-uppercase" style={{ color: '#999' }}
                                dangerouslySetInnerHTML={{ __html: p.address }} />

                        </>
                        :
                        <>
                            <Link
                                to={'/' + p.slug.toLowerCase()}
                                className="is-black"
                                state={{ fromSite: true }}
                            >
                                <p className="is-uppercase is-size-7">
                                    {p.street_address}
                                    {p.address_2 ? ', ' + p.address_2 : ''}
                                </p>
                            </Link>
                            <p className="is-black is-size-7" style={{ color: '#999' }}>
                                {p.bedrooms ? (<span className="is-uppercase">bed {p.bedrooms} </span>) : <></>}
                                {p.bathrooms ? (<span className="is-uppercase">bath {p.bathrooms} </span>) : <></>}
                                {p.parks ? (<span className="is-uppercase">park {p.parks}</span>) : <></>}
                            </p>
                        </>
                }
                {
                    !isOffice ?

                        <p className="is-size-7 is-uppercase" style={{ color: '#999' }}>
                            {p.weekly_rent ? '$' + p.weekly_rent + 'pw' : <span style={{ textTransform: 'uppercase' }}>TBC</span>}
                        </p>
                        : <></>
                }
            </div>
        </div>
    </StyledGridItem>

)

export default GridItem

const StyledGridItem = Styled('div')`
position: relative;
.columns{
    justify-content: space-between;
    padding-top: 10px;
}
p {
    font-weight: 500;
}
.gatsby-image-wrapper {
    opacity: 0.95;
    transition: 0.5s;
}
.is-teal{
    text-align: right;
}
  
}`

const Available = Styled('div')`
    padding: 0.125em 0.25em;
    background: black;
    position: absolute;
    top: 1.5em;
    right: 1.5em;
    color: #fff;
    z-index: 10;
`