import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import 'swiper/css/swiper.css';
import Swiper from 'react-id-swiper';
import styled from '@emotion/styled';

import arrowLeft from '../images/arrow-left.svg';
import arrowRight from '../images/arrow-right.svg';
import arrowRightTeal from '../images/arrow-right-teal.svg';

const GridSlideShow = ({ images, link, isOffice = false }) => {
  const [slideIndex, setIndex] = useState(0);
  const [swiperInstance, setSwiper] = useState(null);

  // Triggered when the slider gets given a new property, to prevent stale slideIndex on grid updates
  useEffect(() => {
    if (slideIndex != 0) swiperInstance.slideTo(0, 0);
  }, link);

  const params = {
    preloadImages: false,
    centeredSlides: true,
    fadeEffect: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    on: {
      slideNextTransitionEnd: () => {
        setIndex(index => index + 1);
      },
      slidePrevTransitionEnd: () => {
        setIndex(index => index + -1);
      },
    },
    getSwiper: swiper => setSwiper(swiper),
  };


  return (
    <StyledSlider className={`slider grid index-${slideIndex}${isOffice ? ' is-office' : ''}`}>
      <Swiper {...params}>
        {images.map((i, k) => (
          <div key={k}>
          {
            isOffice ? 
            <Img fluid={i} />
            :
            <Link to={link} state={{ fromSite: true }}>
              <Img fluid={i} />
            </Link>
          }
            
          </div>
        ))}
      </Swiper>
    </StyledSlider>
  );
};

GridSlideShow.propTypes = {
  // Fluid objects from graphQL gatsby fluid fragment
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  link: PropTypes.string.isRequired,
};

const StyledSlider = styled('div')`
  .swiper-slide {
    .gatsby-image-wrapper {
      & > div {
        padding-bottom: 62.5% !important;
      }
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: #000;
    height: 30px;
    width: 30px;
    margin-top: -15px;
    &.swiper-button-disabled {
      opacity: 0;
    }
    &:after {
      display: block;
      content: ' ';
      background-size: 30px 30px;
      height: 30px;
      width: 30px;
    }

    @media screen and (max-width: 520px) {
      height: 50px;
      width: 50px;
      margin-top: -25px;
      background-size: 30px 30px;
      background-repeat: no-repeat;
      background-position: center center;
      &:after {
        display: inline-block;
        content: ' ';
        background-size: 30px 30px;
        height: 30px;
        width: 30px;
        background-position: center center;
      }
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    opacity: 0;
    transition: 0.5s;
    @media screen and (max-width: 1024px) {
      opacity: 1;
    }
  }
  &:hover {
    .swiper-button-next,
    .swiper-button-prev {
      opacity: 1;
      &.swiper-button-disabled {
        opacity: 0;
      }
    }
  }

  .swiper-button-next {
    background-image: url(${arrowRight});
  }
  .swiper-button-prev {
    background-image: url(${arrowLeft});
  }

  &.index-0 {
    .swiper-button-next {
      &:after {
        background-image: url(${arrowRightTeal});
      }
    }
  }
  
`;

export default GridSlideShow;
