import React from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
import GridItem from './gridItem'

class Grid extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      limit: 4,
      all_properties: Object.assign([], this.props.all_properties.length > 12 ? this.props.all_properties.slice(0, 12) : this.props.all_properties),
      properties: [],
      category: this.props.homeCategory || '',
      priceMin: '',
      priceMax: '',
      beds: '',
      baths: '',
      status: ''


    };
  }

  componentDidMount() {
    this.getMoreProperties();
  }

  getMoreProperties = () => {

    return this.setState((prevState) => ({
      properties: prevState.properties.concat(
        prevState.all_properties.slice(prevState.offset, prevState.offset + prevState.limit),
      ),
      offset: prevState.offset + prevState.limit,
    })
    )

  };
  render() {

    return (
      <div id="properties">
        <InfiniteScroll
          dataLength={this.state.properties}
          next={() => { this.getMoreProperties() }}
          hasMore={
            this.state.properties.length < this.state.all_properties.length
          }
        >
          <div className="section">
            <div className="container">
              <div className="columns is-multiline">
                {this.state.properties.map((p, i) => (
                  <GridItem p={p} i={i} key={i} />
                ))}
              </div>
            </div>
          </div>
        </InfiniteScroll>
      </div >
    );
  }
}

export default Grid;
